.modalWrapper{
    background-color: white;
    border-radius: 5px;
    margin-top: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalWrapper p{
    margin-top: 5px;
}
.modalWrapper h2{
    font-size: large;
    font-weight: bold;
}

.secondaryLink{
    color: blue;
    margin-top: 20px;
}

.button{
    background-color: blue;
    color: white;
    padding: 20px;
    margin-top: 20px;
    border-radius: 20px; 
}